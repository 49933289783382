.spinnerWrap {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.spinner-container>div {
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

#loader-image{
    height: 70px;
    width: 70px;
}

/* .spinner {
    width: 40px;
    height: 40px;
    background: transparent;
    background: linear-gradient(90deg, #009fff, rgb(189, 235, 244));
} */

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

#spinner3 {
    border-radius: 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    animation: rotate 2s linear infinite;
}

#spinner3::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    border-radius: 50%;
    animation: shadow 2s linear infinite;
}

@keyframes shadow {
    0% {
        box-shadow: inset 0 0 15px 15px rgb(32, 32, 32), 0 0 25px 25px rgb(142, 181, 189);
    }
    50% {
        box-shadow: inset 0 0 25px 25px rgba( 255, 255, 255, 0.18);
    }
    100% {
        box-shadow: inset 0 0 15px 15px rgb(32, 32, 32), 0 0 25px 25px rgba( 255, 255, 255, 0.18);
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Neuemontreal Book';
  src: url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddc9530420a63201598_PPNeueMontreal-Book.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddb6ee2de687f7f4d07_PPNeueMontreal-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eiko';
  src: url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddb6ac74a8d81fd0628_PPEiko-Thin.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddb1aa2faefbdd941e7_PPEiko-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neuemontreal Medium';
  src: url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddb67c6b7807f817d0f_PPNeueMontreal-Medium.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddb1aa2fa08ebd941e8_PPNeueMontreal-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neuemontreal Regular';
  src: url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddc2a48af331f62aadc_PPNeueMontreal-Regular.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddb2a48af880d62aada_PPNeueMontreal-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neuemontreal SemiBold';
  src: url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddc3e9f3fab10092822_PPNeueMontreal-SemiBold.woff2') format('woff2'), url('https://uploads-ssl.webflow.com/62f3aea275c72a672c3b8ed3/6436dddc9530426174201597_PPNeueMontreal-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Neuemontreal Regular','Neuemontreal Medium', 'Neuemontreal SemiBold', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:invalid {
  color: #6f88a5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTableRow-root {
  height: 0px !important;
  display: none !important;
  border-bottom: none;
}
/* #table-ed>:not(:first-child) {
    border-top: none !important;
} */

#table-team > :not(caption) > * > * {
  /* padding: 0.5rem 0.5rem; */
  padding: 25px 15px !important;
  background-color: var(--bs-table-bg) !important;
  border-bottom-width: 1px !important;
  height: 75px !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg) !important;
}
#add-user-modal .modal-body {
  padding: 0;
}

#add-user-modal .modal-content {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 445px !important;
  color: var(--bs-modal-color) !important;
  pointer-events: auto !important;
  background-color: var(--bs-modal-bg) !important;
  background-clip: padding-box !important;
  /* border: var(--bs-modal-border-width) solid var(--bs-modal-border-color); */
  border-radius: 8px !important;
  outline: 0 !important;
  align-items: center !important;
  padding: 5px;
}

#add-user-modal .modal-content .modal-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  background-color: var(--bs-modal-header-bg) !important;
  border-top-left-radius: calc(0.3rem - 1px) !important;
  border-top-right-radius: calc(0.3rem - 1px) !important;
  padding: 24px 28px 0px 28px !important;
  text-decoration: none !important;
  border: none !important;
}

#edit-user-modal .modal-body {
  padding: 0;
}

#edit-user-modal .modal-content {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 445px !important;
  color: var(--bs-modal-color) !important;
  pointer-events: auto !important;
  background-color: var(--bs-modal-bg) !important;
  background-clip: padding-box !important;
  /* border: var(--bs-modal-border-width) solid var(--bs-modal-border-color); */
  border-radius: 8px !important;
  outline: 0 !important;
  align-items: center !important;
  padding: 5px;
}

#edit-user-modal .modal-content .modal-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  background-color: var(--bs-modal-header-bg) !important;
  border-top-left-radius: calc(0.3rem - 1px) !important;
  border-top-right-radius: calc(0.3rem - 1px) !important;
  padding: 24px 28px 0px 28px !important;
  text-decoration: none !important;
  border: none !important;
}

#remove-user-modal .modal-content {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 546px !important;
  height: 276px !important;
  color: var(--bs-modal-color) !important;
  pointer-events: auto !important;
  background-color: var(--bs-modal-bg) !important;
  background-clip: padding-box !important;
  /* border: var(--bs-modal-border-width) solid var(--bs-modal-border-color); */
  border-radius: 8px !important;
  outline: 0 !important;
  /* align-items: center !important; */
  padding: 24px 20px 20px 20px !important;
}

#pick-option-modal .modal-content {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 546px !important;
  height: 19rem !important;
  color: var(--bs-modal-color) !important;
  pointer-events: auto !important;
  background-color: var(--bs-modal-bg) !important;
  background-clip: padding-box !important;
  /* border: var(--bs-modal-border-width) solid var(--bs-modal-border-color); */
  border-radius: 8px !important;
  outline: 0 !important;
  /* align-items: center !important; */
}

#no-border .modal-content{
    border-radius: 8px !important;
    border: 1px solid #ffffff;
}

.nav-bar-dropdown-container-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto 0px auto 0px;
}

.dropdown-header-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropdown-icon-profile {
    height: 16.5px;
    width: 16.5px;
    margin: auto 5px auto 4px;
}

.dropdown-chevron-profile {
    height: 12.75px;
    width: 15.62px;
    margin: auto 5px auto 4px;
}

.dropdown-option-icon-profile {
    height: 18.33px;
    width: 19.17px;
    margin: auto 5px auto 4px;
    color: #04506E;
}

.dropdown-header-text-profile {
    height: 24px;
    width: 91px;
    color: #101010;
    font-family: "Neuemontreal Medium";
    font-size: 16px;
    /* font-weight: 600; */
    letter-spacing: 0.9px;
    line-height: 24px;
    text-align: center;
}

.dropdown-options-container-profile {
    position: absolute;
    height: 10.625rem;
    width: 20rem;
    top: 4.75rem;
    right: 33px;
    background-color: #ffffff;
    box-shadow: 0px -3px 20px rgba(241, 245, 247, 1);
    border-radius: 8px;
    z-index: 1;
}

.dropdown-options-list-profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    padding: 30.42px 10px 19.16px 0px;
}

.dropdown-option-row-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    cursor: pointer;
}

.dropdown-option-text-profile {
    color: #101010;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 21px;
}

.dropdown-option-text-profile:active {
    color: #04506E;
}

.dropdown-profile-username {
    height: 24px;
    color: #101010;
    font-family: "Neuemontreal Medium";
    font-size: 16px;
    /* font-weight: 600; */
    margin-left: 15px;
    letter-spacing: 0.15px;
    line-height: 24px;
}

.dropdown-profile-email {
    height: 17px;
    color: #878787;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    margin-left: 15px;
    letter-spacing: 0.4px;
    line-height: 18px;
}

.dropdown-profile-divider {
    height: 1px;
    width: 320px;
    background-color: #D3D3D3;
    opacity: 0.55;
}

.dropdown-profile-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
    height: 43.03px;
    width: 100%;
    cursor: pointer;
}

.dropdown-profile-icon {
    height: 20px;
    width: 20px;
    margin-right: 14px;
}

.dropdown-profile-text {
    height: 21px;
    color: #101010;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 21px;
}
#dropdown_menu {
    --bs-dropdown-min-width: auto !important;
}

#basic-navbar-nav > .container {
    flex-grow: 1; /* Takes available space */
    justify-content: center; /* Centers navigation items */
}

#nav-bar img,
video {
    max-width: 180px;
    height: auto;
}

#nav-bar {
    background-color: #ffffff;
    height: 72px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    #nav-bar {
      /* Change the height of the navbar on small screens */
      height: 56px;
    }
    #basic-navbar-nav > .container {
      flex-direction: column; /* Stack navigation items on top of each other */
    }
  }

#dropdown-basic {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#nav-item {
    height: 24px;
    width: 81px;
    color: #101010;
    font-family: "Neuemontreal Medium";
    font-size: 16px;
    /* font-weight: 600; */
    letter-spacing: 0.9px;
    line-height: 24px;
    text-align: center;
    display: flex;
    flex-direction: row;
    margin: 0 10px;
}

#nav-text {
    color: #101010;
}

#nav-item-text {
    color: #101010;
    font-family: "Neuemontreal Medium";
    font-size: 16px;
    /* font-weight: 600; */
    letter-spacing: 0.15px;
    line-height: 24px;
    text-align: center;
}

.icon-container {
    background-color: #f2f2f2;
  border-radius: 8px;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#nav-item-icon {
    height: 16px;
    width: 16px;
}

#avatar {
    margin: auto 0 auto 10px;
    background-color: #101010;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
}

.dropdown-avatar-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100px;
}
.nav-bar-dropdown-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right:2.5rem;
}

.dropdown-header- {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon-dropdown-container {
    background-color: #f5f9fa;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.dropdown-icon {
    height: 16.5px;
    width: 16.5px;
    margin: auto 5px auto 4px;
}

.dropdown-chevron{
    height: 12.75px;
    width: 15.62px;
    margin: auto 5px auto 4px;
}

.dropdown-option-icon {
    height: 18.33px;
    width: 19.17px;
    margin: auto 5px auto 4px;
    color: #052ebd;
}

.dropdown-header-text {
    height: 24px;
    color: #101010;
    font-family: "Neuemontreal Medium";
    font-size: 16px;
    /* font-weight: 600; */
    letter-spacing: 0.9px;
    line-height: 24px;
    text-align: center;
}

.dropdown-options-container {
    position: absolute;
    height: 7.5625rem;
    width: 16.5rem;
    top: 2.5rem;
    background-color: #ffffff;
    box-shadow: 0 -5px 12px -3px rgba(195, 195, 195, 0.16), 0 24px 32px -3px rgba(195, 195, 195, 0.16);
    border-radius: 8px;
    z-index: 1;
}

.dropdown-options-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    padding: 22px 10px 16px 18px;
}

.dropdown-option-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    cursor: pointer;
}

.dropdown-option-text{
    color: #101010;
    font-family: "Neuemontreal Book";
    font-size: 16px;
    letter-spacing: 0.29px;
    line-height: 21px;
    -webkit-text-stroke-width: 0.2px;
}

.dropdown-option-text:active {
    color: #052ebd;
}